<template>
  <form
    action="#"
    @submit.prevent="submit"
    @keyup="removeError($event.target.name)"
    @change="removeError($event.target.name)"
  >
    <div class="h-60 mx-h-60">
      <p class="mb-4 text-sm">{{ $t("pages.login.root_login") }}</p>
      <div class="form-group pb-3">
        <base-input
          name="email"
          :placeholder="$t('label.main.pages.login.email')"
          :class="[
            !!errors.email
              ? ['border-red-900 placeholder-red-900']
              : ['border-light-green placeholder-gray-500'],
          ]"
          v-model="form.email"
        />

        <label
          for="email"
          class="text-xs text-gray-500"
          :class="!!errors.email ? 'text-red-900' : 'text-gray-500'"
        >
          {{
            !!errors.email
              ? errors.email[0]
              : $t("label.main.pages.login.email")
          }}
        </label>
      </div>

      <div class="form-group pb-3">
        <base-input
          type="password"
          name="password"
          :placeholder="$t('label.main.pages.login.password')"
          :class="[
            !!errors.password
              ? ['border-red-900 placeholder-red-900']
              : ['border-light-green placeholder-gray-500'],
          ]"
          v-model="form.password"
        />

        <label
          for="name"
          class="text-xs text-gray-500"
          :class="!!errors.password ? 'text-red-900' : 'text-gray-500'"
        >
          {{
            !!errors.password
              ? errors.password[0]
              : $t("label.main.pages.login.password")
          }}
        </label>
      </div>

      <div class="flex justify-between mt-10">
        <div class="form-check">
          <label
            class="checkbox__container checkbox__container--block"
            for="terms"
          >
            {{ $t("label.main.pages.login.remember_me") }}
            <input
              class="answers-checkbox"
              id="terms"
              type="checkbox"
              v-model="form.remember"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <router-link
          :to="{ name: 'forgotPassword' }"
          class="text-dark-green no-underline font-medium"
        >
          {{ $t("link.main.pages.login.forgot_password") }}?
        </router-link>
      </div>
    </div>

    <div class="flex justify-between mt-12">
      <router-link
        class="bg-white text-dark-green border border-dark-green hover:bg-dark-green hover:text-white p-1 px-10 text-center transition ease-in duration-150"
        :to="{ name: 'register' }"
      >
        {{ $t("link.main.pages.login.register") }}
      </router-link>

      <button
        :disabled="loading"
        :title="hasErrors ? $t('valiation.pages.auth.resolve_errors') : ''"
        type="submit"
        class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90"
        :class="{ 'cursor-not-allowed': loading }"
      >
        {{ $t("heading.main.pages.login.login") }}
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </button>
    </div>
  </form>
</template>


<script>
export default {
  name: "RootLogin",
};
</script>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { reactive, computed, inject, ref } from "vue";
import authenticate from "@/composables/auth/authenticate";
import validator from "@/composables/auth/validator";
import Checkbox from "primevue/checkbox";
import LoginCompany from "@/modules/login/index.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

const form = reactive({
  email: "",
  password: "",
  remember: false,
});

const router = useRouter();
const route = useRoute();
const notify = inject("notify");
const t = inject("t");
const { login, loading, getAuth: auth } = authenticate();
const { errors, clearErrors, validate, removeError } = validator();
import { watch } from "vue";

if (route.query && route.query.token && route.query.referrer) {
  localStorage.setItem("pluginToken", route.query.token);
  localStorage.setItem("pluginUrl", route.query.referrer);
}

const submit = async () => {
  validate({
    data: form,
    rules: {
      email: ["required", "email"],
      password: ["required"],
    },
  });

  if (hasErrors.value) {
    notify({
      message: "Please fix all the errors",
      type: "danger",
    });
    return;
  }

  loading.value = true;

  try {
    await login(form);
    loading.value = false;
    clearErrors();
    clearForm();
    loading.value = false;
    // redirectUser();
  } catch (e) {
    notify({
      message: t("validation.main.pages.login.error_msg"),
      type: "danger",
    });
    loading.value = false;
  }
};

const redirectUser = () => {
  console.log('Redirecting user');
  // if (auth.value && !!auth.value.email_verified_at == true) {
  //   router.push({ name: "Home" });
  //   return;
  // } else {
  //   router.replace({ name: "BaseProfile" });
  // }
};

const hasErrors = computed(() => {
  return Object.values(errors.value).length;
});

watch(
  errors,
  () => {
    if (errors.value) {
      setTimeout(() => {
        errors.value = {};
      }, 550);
    }
  },
  {
    deep: true,
  }
);

const clearForm = () => {
  form.email = "";
  form.password = "";
  form.remember = false;
};
</script>

<style>
</style>