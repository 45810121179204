<template>
  <form
    action="#"
    @submit.prevent="submit"
    @keyup="removeError($event.target.name)"
    @change="removeError($event.target.name)"
    class="flex flex-col justify-between h-full"
  >
    <div class="h-60 mx-h-60">
      <p class="mb-4 text-sm">{{ $t("pages.login.user_login") }}</p>
      <div class="form-group pb-3">
        <base-input
          name="username"
          :placeholder="$t('label.main.pages.login.username')"
          :class="[
            !!errors.username
              ? ['border-red-900 placeholder-red-900']
              : ['border-light-green placeholder-gray-500'],
          ]"
          v-model="form.username"
        />

        <label
          for="email"
          class="text-xs text-gray-500"
          :class="!!errors.username ? 'text-red-900' : 'text-gray-500'"
        >
          {{
            !!errors.username
              ? errors.username[0]
              : $t("label.main.pages.login.username")
          }}
        </label>
      </div>

      <div class="form-group pb-3">
        <base-input
          type="password"
          name="password"
          :placeholder="$t('label.main.pages.login.password')"
          :class="[
            !!errors.password
              ? ['border-red-900 placeholder-red-900']
              : ['border-light-green placeholder-gray-500'],
          ]"
          v-model="form.password"
        />

        <label
          for="name"
          class="text-xs text-gray-500"
          :class="!!errors.password ? 'text-red-900' : 'text-gray-500'"
        >
          {{
            !!errors.password
              ? errors.password[0]
              : $t("label.main.pages.login.password")
          }}
        </label>
      </div>

      <div class="form-group pb-3">
        <base-input
          type="text"
          name="domain"
          :placeholder="$t('label.main.pages.login.client_id')"
          :class="[
            !!errors.domain
              ? ['border-red-900 placeholder-red-900']
              : ['border-light-green placeholder-gray-500'],
          ]"
          v-model="form.domain"
        />

        <label
          for="name"
          class="text-xs text-gray-500"
          :class="!!errors.domain ? 'text-red-900' : 'text-gray-500'"
        >
          {{
            !!errors.domain
              ? errors.domain[0]
              : $t("label.main.pages.login.client_id")
          }}
        </label>
      </div>
    </div>

    <div class="flex justify-between mt-12">
      <div></div>

      <button
        :disabled="loading"
        :title="hasErrors ? $t('valiation.pages.auth.resolve_errors') : ''"
        type="submit"
        class="bg-dark-green text-gray-200 p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90"
        :class="{ 'cursor-not-allowed': loading }"
      >
        {{ $t("heading.main.pages.login.login") }}
        <font-awesome-icon
          icon="circle-notch"
          class="fa-spin"
          v-show="loading"
        />
      </button>
    </div>
  </form>
</template>

<script>
import { computed, inject, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import authenticate from "@/composables/auth/authenticate";
import validator from "@/composables/auth/validator";

export default {
  name: "LoginCompany",

  setup() {
    const form = ref({
      username: "",
      password: "",
      domain: "",
    });

    const router = useRouter();
    const route = useRoute();
    const t = inject("t");
    const notify = inject("notify");
    const { userLogin, loading, getAuth: auth } = authenticate();
    const { errors, clearErrors, validate, removeError } = validator();

    const hasErrors = computed(() => {
      return Object.values(errors.value).length;
    });

    const submit = async () => {
      validate({
        data: form.value,
        rules: {
          username: ["required"],
          password: ["required"],
          domain: ["required"],
        },
      });

      if (hasErrors.value) {
        notify({
          message: "Please fix all the errors",
          type: "danger",
        });
        return;
      }

      loading.value = true;

      try {
        await userLogin(form.value);
        loading.value = false;
        clearErrors();
        clearForm();
        loading.value = false;
        // redirectUser();
      } catch (e) {
        notify({
          message: t("validation.main.pages.login.error_msg"),
          type: "danger",
        });
        loading.value = false;
      }
    };

    const redirectUser = () => {
      if (auth.value && !!auth.value.email_verified_at == true) {
        router.push({ name: "Home" });
        return;
      } else {
        router.replace({ name: "BaseProfile" });
      }
    };

    watch(
      hasErrors,
      () => {
        if (hasErrors?.value > 0) {
          setTimeout(() => {
            errors.value = {};
          }, 1550);
        }
      },
      {
        deep: true,
      }
    );

    const clearForm = () => {
      form.username = "";
      form.password = "";
      form.domain = "";
    };

    return {
      userLogin,
      auth,
      form,
      loading,
      errors,
      hasErrors,
      clearErrors,
      validate,
      removeError,
      redirectUser,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>