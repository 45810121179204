<template>
  <main class="login-page flex justify-center items-center h-screen">
    <div
      class="xl:w-2/3 lg:w-2/3 md:w-3/4 sm:w-11/12 rounded shadow-card grid xl:grid-cols-2 lg:grid-cols-2"
    >
      <div class="image-and-logo bg-light-gray">
        <div class="logo-images flex justify-center items-center my-8">
          <img
            src="@/assets/images/main-logo.png"
            alt="Xlinx Logo"
            class="mr-2 align-top"
          />
        </div>

        <div class="register-icon mx-auto">
          <img
            src="@/assets/images/login-image.png"
            alt="Register Image"
            class="w-full h-full"
          />
        </div>
      </div>

      <div class="font-sans p-8 text-left">
        <div class="mb-4">
          <h4 class="text-2xl font-bold">
            {{ $t("heading.main.pages.login.login") }}
          </h4>
          <p class="mt-2 text-dark-gray">
            {{ $t("text.main.pages.login.welcome_back") }}
          </p>
        </div>

        <TabView>
          <TabPanel header="Root login">
            <RootLogin />
          </TabPanel>
          <TabPanel header="User login">
            <LoginCompany />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "login",
};
</script>

<script setup>
import LoginCompany from "@/modules/login/index.vue";
import RootLogin from "@/modules/root-login/index.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
</script>

<style scoped lang="scss">
.logo-images {
  .image2 {
    width: 200px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 170px;
    }
    @media (max-width: 900px) {
      width: 150px;
    }
    @media (max-width: 600px) {
      width: 120px;
    }
  }
  .image3 {
    width: 103px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 90px;
    }
    @media (max-width: 900px) {
      width: 80px;
    }
    @media (max-width: 600px) {
      width: 70px;
    }
  }
}

.register-icon {
  width: 300px;
  height: 300px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}

.border-b-half {
  border-bottom-width: 0.5px;
}
</style>
